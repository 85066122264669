$primary : #3f5176;
$primary-lightened : #f5e174;
$secondary: #92278f;
$dark : #121921;
$white : #fff;
$secondary-dark : #372675;
$margin-nav : 101px;
$success: rgb(31, 133, 31);
$danger: rgb(168, 16, 16);
$info: rgb(226, 205, 21);


    
$theme-colors: (
    "primary": $primary,
    "success": $success,
    "primary-lightened" : $primary-lightened,   
    "secondary": $secondary,
    "dark" : $dark,
    "info" : $info,
    "danger" : $danger,
    "secondary-dark" :$secondary-dark,
    "white" : $white
);

