.search{
    overflow-x: hidden;
    .form-container{
        max-height: 150px;

        .results{
            background-color: white;
            overflow-y: scroll;
            border:1px solid #333;
            max-height: 200px;
            z-index:2;
            ul{
                padding: 0px !important;
                border:1px solid red;
            }
            li{
                padding:5px 2px;
                &:hover{
                    cursor: pointer;
                    background-color: #eee;
                }
            }
            
        }
    }
    #table_results > div{
        
            min-height: 300px;

        
    }
}