.menu{

    .navbar-nav{
        .nav-link{
            color:white;
            border-bottom:1px solid transparent;
            transition: all .5s ease-in;
            &:hover{
                border-bottom:1px solid white;
                color:white;
            }
        }
    }
}