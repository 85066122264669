.autocomplete-component {
  ul {
    /* width */
    &::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgb(182, 182, 182);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: rgb(184, 183, 183);
    }
  }
}
.autocomplete {
  position: relative;
  #results {
    position: absolute;
    background-color: white;
    z-index: 100;
    top: 40px;
    min-width: 100%;
    max-width: max-content;
    min-height: 0;
    border: 1px solid #aaa;
    li {
      margin-bottom: 1px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
