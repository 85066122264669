.login {
  width: 100vw;
  min-height: 100vh;
  height: 100vh;
  .left-side {
    height: 100vh;
    background-image: url("../../assets/img/login.jpg");
    background-position: center center;
    background-size: cover;
  }
  .right-side {
      height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .logo {
      width: 200px;
    }
    form {
      width: 75%;
      margin-top: 50px;
      .form-group {
        display: flex;
        flex-direction: column;
        text-align: right;
        label {
          margin-bottom: 20px;
          margin-top: 20px;
        }
      }
    }

    @media (max-width: 767px){
        background-image: url("../../assets/img/login.jpg");
        background-position: center center;
        background-size: cover;
        label{
            color:white;
        }
    }
  }
}
